import React, { useState, useContext, useMemo } from 'react';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Tooltip } from 'primereact/tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { DataPoolView, NewDataPoolDialog, Filters, AcrossSearch } from './components';
import './styles.css';
import { GeneralContext } from '../../../../store';
import {
	useGetPinnedDatapoolIds,
	useGetPrivateDatapools,
	useGetPublicDatapools,
} from '../../queryHooks';
import { Loading } from '../../../../components';

const FiltersAndData = () => {
	const [filterSelections, setFilterSelections] = useState({ crops: [], countries: [], regions: [], filter: false });
	const [dialogStatus, setDialogStatus] = useState(false);
	const [refreshData, setRefreshData] = useState(0);

	const { activeIndex, setActiveIndex } = useContext(GeneralContext);

	const { user, isAuthenticated } = useAuth0();

	const publicDatapools = useGetPublicDatapools(activeIndex);

	const userDatapools = useGetPrivateDatapools(activeIndex, user?.sub);

	const pinnedDatapoolIds = useGetPinnedDatapoolIds(activeIndex, isAuthenticated, user);

	const pinnedDatapools = useMemo(() => {
		if (isAuthenticated) {
			if (publicDatapools.isSuccess && pinnedDatapoolIds.isSuccess) {
				const temp = publicDatapools.data?.datapools?.filter((item) => {
					if (pinnedDatapoolIds.data.find((it) => it.datapool_id === item.datapool_id)) {
						return true;
					}
					return false;
				});
				return temp;
			}
		}
		return [];
	}, [publicDatapools, pinnedDatapoolIds]);

	const renderTab = (item, itemIndex) => (
		<>
			<Tooltip target={`.tab-${itemIndex}`} content={item.tooltip} position="bottom" />
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<a className={`p-menuitem-link flex align-items-center gap-2 tab-${itemIndex}`} onClick={() => setActiveIndex(itemIndex)}>
				<span>{item.name}</span>
			</a>
		</>
	);

	const publicItems = [
		{
			tooltip: 'Search data across all DataPools',
			name: 'Search Across Public Datapools',
			template: (item) => renderTab(item, 0),
		},
		{
			name: 'Browse Public Datapools',
			tooltip: 'Dive inside specific DataPools',
			template: (item) => renderTab(item, 1),
		},
	];
	const privateItems = [
		{
			tooltip: 'Search data across all DataPools',
			name: 'Search Across Public Datapools',
			template: (item) => renderTab(item, 0),
		},
		{
			name: 'Browse Public Datapools',
			tooltip: 'Dive inside specific DataPools',
			template: (item) => renderTab(item, 1),
		},
		{
			label: 'Favourite Datapools',
		},
		{
			name: 'My Datapools',
			tooltip: 'Create your own pool of data',
			template: (item) => renderTab(item, 3),
		},
	];

	const assignTabData = () => {
		let data = {};
		switch (activeIndex) {
		case 0: data = publicDatapools.data; break;
		case 1: data = publicDatapools.data; break;
		case 2: data = { datapools: pinnedDatapools, filters: publicDatapools.filters }; break;
		case 3: data = userDatapools.data; break;
		default: break;
		}
		if (data && data.datapools) {
			return data;
		}
		return {};
	};

	const assignTabTag = () => {
		switch (activeIndex) {
		case 0: return 'across';
		case 1: return 'public';
		case 2: return 'pinned';
		case 3: return 'private';
		default: return 'public';
		}
	};

	const loading = () => {
		switch (activeIndex) {
		case 0: return publicDatapools.isFetching;
		case 1: return publicDatapools.isFetching;
		case 2: return publicDatapools.isFetching && pinnedDatapoolIds.isFetching;
		case 3: return userDatapools.isFetching;
		default: return false;
		}
	};

	const filterDatapools = (data) => {
		if (!data) {
			return [];
		}
		if (!filterSelections.filter) return data;
		const newFilteredData = data.filter((dp) => {
			const foundCrop = dp.tags.find((item) => {
				if (filterSelections.crops.find((crop) => crop === item.tag)) {
					return true;
				}
				return false;
			});
			const foundRegion = dp.tags.find((item) => {
				if (filterSelections.regions.find((region) => region === item.tag)) {
					return true;
				}
				return false;
			});
			const foundCountry = dp.tags.find((item) => {
				if (filterSelections.countries.find((country) => country === item.tag)) {
					return true;
				}
				return false;
			});
			if ((foundCrop || !filterSelections.crops.length) && (foundRegion || !filterSelections.regions.length) && (foundCountry || !filterSelections.countries.length)) {
				return true;
			}
			return false;
		});
		return newFilteredData;
	};

	return (
		<div className="home-page">
			<div className="tabs">
				<TabMenu
					model={isAuthenticated ? privateItems : publicItems}
					activeIndex={activeIndex}
					onTabChange={(e) => {
						setFilterSelections({ crops: [], countries: [], regions: [], filter: false });
						// setFilteredData(null);
						setActiveIndex(e.index);
					}}
				/>
				<div className="table-card">
					{activeIndex ? (
						<Filters
							data={assignTabData()}
							setFilterSelections={setFilterSelections}
							filterSelections={filterSelections}
						/>
					) : null}
					{activeIndex === 3 ? <Button className="new-datapool-button" label="New Datapool" onClick={() => setDialogStatus(true)} /> : null}
					{activeIndex
						? (
							<DataPoolView
								tab={assignTabTag()}
								filteredData={filterDatapools(assignTabData()?.datapools)}
								pinnedDatapools={pinnedDatapools}
								loading={loading()}
							/>
						)
						: (
							<Loading loading={publicDatapools.isLoading}>
								{publicDatapools.isFetched
									? (<AcrossSearch datapools={publicDatapools.data?.datapools} />)
									: null}
							</Loading>
						)}
				</div>
			</div>
			<NewDataPoolDialog dialogStatus={dialogStatus} setDialogStatus={setDialogStatus} user={user} setRefreshData={setRefreshData} refreshData={refreshData} />
		</div>
	);
};

export default FiltersAndData;
